import { Grid } from "@mui/material";
import React from "react";
import theme from "../../../theme";
import { Footer, FormLogin, NavBar } from "../Components";
import { BodyContainer, Text, Titule } from "../Styled-components";
import { StyledMUIBox } from "../../../StyledCrossComponents";
import { StyledMUIBoxLogin } from "../Styled-components";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.Palette.quinary,
          fontFamily: theme.Font.primary,
          padding: "20px",
        }}
      >
        <NavBar backgroundcolor={theme.Palette.quinary} />
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="center"
          sx={{ maxWidth: theme.Breakpoint.xlarge }}
        >
          <Grid item xs={12} sm={6}>
            <Titule variant="h1">{t("login.title_1")}</Titule>
            <Text>{t("login.description_1")}</Text>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledMUIBoxLogin>
              <FormLogin />
            </StyledMUIBoxLogin>
          </Grid>
        </Grid>
      </Grid>
      <BodyContainer>
        <Footer />
      </BodyContainer>
    </>
  );
};
