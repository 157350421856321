import * as React from "react";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import {
  AccountBalanceWallet,
  PriceCheck,
  Settings,
} from "@mui/icons-material";
import styled from "styled-components";
import { useUserStore } from "../../../store";

export default function ListItems() {
  const [openGroup, setOpenGroup] = React.useState(links);
  const sx = { color: "white" };
  const { user } = useUserStore();

  const handleClickOpen = (item) => {
    item.state = !item.state;
    openGroup.map((p) => {
      if (p.nameItem !== item.nameItem) {
        p.state = false;
      }
    });
    setOpenGroup([...openGroup], item);
  };

  return (
    <List component="nav" sx={{ flexGrow: 1 }}>
      {openGroup.map((item) => (
        <main key={item.name}>
          {item.rol.some((role) => user.roles.includes(role)) && (
            <ListItemButton
              key={item.nameItem}
              onClick={() => handleClickOpen(item)}
            >
              <ListItemIcon>{item.icon()}</ListItemIcon>
              <ListItemText sx={sx}>{item.nameItem}</ListItemText>
              {item.state ? <ExpandLess sx={sx} /> : <ExpandMore sx={sx} />}
            </ListItemButton>
          )}
          {item.subItems &&
            item.subItems.map((j) => (
              <>
                <Collapse
                  key={j.name}
                  in={item.state}
                  timeout="auto"
                  unmountOnExit
                >
                  {j.nameItem ? (
                    <>
                      <ListItemButton
                        key={j.nameItem}
                        sx={{ pl: 10 }}
                        onClick={() => handleClickOpen(j)}
                      >
                        <ListItemIcon>{j.icon()}</ListItemIcon>
                        <ListItemText sx={sx}>{j?.nameItem}</ListItemText>
                        {j.state ? <ExpandLess sx={sx} /> : <ExpandMore />}
                      </ListItemButton>
                      {j.subItems &&
                        j.subItems.map((z) => (
                          <Collapse
                            key={z.name}
                            in={item.state}
                            timeout="auto"
                            unmountOnExit
                          >
                            <List component="div" disablePadding>
                              <ListItemButton sx={{ pl: 15 }}>
                                <StyledMUINavLink to={z.route}>
                                  <ListItemText sx={sx}>{z.name}</ListItemText>
                                </StyledMUINavLink>
                              </ListItemButton>
                            </List>
                          </Collapse>
                        ))}
                    </>
                  ) : (
                    <List component="div" disablePadding>
                      <ListItemButton sx={{ pl: 10 }}>
                        <StyledMUINavLink to={j.route}>
                          <ListItemText sx={sx}>{j.name}</ListItemText>
                        </StyledMUINavLink>
                      </ListItemButton>
                    </List>
                  )}
                </Collapse>
              </>
            ))}
        </main>
      ))}
    </List>
  );
}

const links = [
  {
    nameItem: "Pagos",
    state: false,
    rol: ["Asesor", "Administrador"],
    icon: () => <AccountBalanceWallet sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Realizar Pago",
        route: "/Dashboard/Payments",
      },
      {
        name: "Pagos Realizados",
        route: "/Dashboard/PaymentsList",
      },
    ],
  },
  {
    nameItem: "Liquidar",
    state: false,
    rol: ["Administrador"],
    icon: () => <PriceCheck sx={{ color: "white" }} />,
    subItems: [
      {
        name: "Liquidar Pagos",
        route: "/Dashboard/SettlePaymentsList",
      },
    ],
  },
  // {
  //   nameItem: "Reportes",
  //   state: false,
  //   icon: () => <Feed sx={{ color: "white" }} />,
  //   subItems: [
  //     {
  //       name: "Pagos Realizados",
  //       route: "/Dashboard/ConIndex/ConTipoCuentaView",
  //     },
  //     {
  //       name: "Liquidaciones Realizadas",
  //       route: "/Dashboard/ConIndex/ConTipoCuentaView",
  //     },
  //   ],
  // },
  {
    nameItem: "Configuraciones",
    state: false,
    rol: ["Administrador"],
    icon: () => <Settings sx={{ color: "white" }} />,
    subItems: [
      // {
      //   name: "Configurar cuentas bancarias",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      // {
      //   name: "Configurar beneficiarios",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      {
        name: "Configurar usuarios",
        route: "/Dashboard/CreateUserByAgency",
      },
    ],
  },
  {
    nameItem: "Control de Pagos",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      // {
      //   name: "Configurar cuentas bancarias",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      // {
      //   name: "Configurar beneficiarios",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      {
        name: "Lista de Pagos",
        route: "/Dashboard/PaymentAdmin",
      },
    ],
  },
  {
    nameItem: "Agencias",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      // {
      //   name: "Configurar cuentas bancarias",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      // {
      //   name: "Configurar beneficiarios",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      {
        name: "Lista de Agencias",
        route: "/Dashboard/AgencyAdmin",
      },
    ],
  },
  {
    nameItem: "Configuraciones",
    state: false,
    icon: () => <Settings sx={{ color: "white" }} />,
    rol: ["Owner"],
    subItems: [
      // {
      //   name: "Configurar cuentas bancarias",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      // {
      //   name: "Configurar beneficiarios",
      //   route: "/Dashboard/ConIndex/ConTipoCuentaView",
      // },
      {
        name: "Configurar exchange rate",
        route: "/Dashboard/ExchangeAdmin",
      },
    ],
  },
];

const StyledMUINavLink = styled(NavLink)`
  && {
    text-decoration: none;
  }
`;
