import React, { useState } from "react";
import { MenuItem } from "@mui/material";
import { StyledMUISelectDataGrid } from "../../../StyledCrossComponents";
import { useFetchPut } from "../../../Hooks/useFetchPut";
import { useStorageModal } from "../../../store";
import { CrossLoader } from "../../CrossComponents";

export const SelectState = ({ props, statePaymentlist, setModalAlert }) => {
  const [stateValue, setStateValue] = useState(props.row);
  const { SubmitUpdate } = useFetchPut();
  const { setModalCross } = useStorageModal();
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const handleSelectChange = (event, id) => {
    setLoader(true);
    const newStateValue = {
      ...stateValue,
      stateId: event.target.value,
    };

    if (event.target.value <= id) {
      setLoader(false);
      setModalAlert({
        openModal: true,
        message: "Operación no permitida",
        severity: "error",
        variant: "filled",
      });
      return;
    } else {
      setStateValue({ ...stateValue, stateId: event.target.value });
      SubmitUpdate(
        newStateValue,
        `Payments/${stateValue.paymentReference}`
      ).then((response) => {
        if (!response.ok) {
          setLoader(false);
          setModalAlert({
            openModal: true,
            message: "Error al actualizar el estado del pago",
            severity: "error",
            variant: "filled",
          });
          return;
        }
        setLoader(false);
        setModalAlert({
          openModal: true,
          message: "Estado actualizado",
          severity: "success",
          variant: "filled",
        });
      });
    }
  };

  return (
    <StyledMUISelectDataGrid
      key={props.row.id}
      labelId={`select-label-${props.row.id}`}
      id={`select-props-${props.row.id}`}
      value={stateValue.stateId}
      fullWidth
      onChange={(event) => {
        handleSelectChange(event, stateValue.stateId);
      }}
    >
      {statePaymentlist.data.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.nameState}
        </MenuItem>
      ))}
    </StyledMUISelectDataGrid>
  );
};
