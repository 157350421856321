import React, { useEffect, useState } from "react";
import { useStoreModalNotification, useUserStore } from "../store";
import { useNavigate } from "react-router-dom";

export const useFetchGet = (apiController) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [controller, setController] = useState(null);
  const baseUrl = import.meta.env.VITE_REACT_APP_BASE_URL;
  //const navigate = useNavigate();

  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  useEffect(() => {
    const fetchData = async () => {
      //setIsLoading(true);
      try {
        const response = await fetch(`${baseUrl}${apiController}`, {
          credentials: "include",
        });

        if (response.status === 401) {
          setModalAlert({
            openModal: true,
            message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
            severity: "error",
            variant: "filled",
          });
          window.location.href = "/";
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const json = await response.json();
        setData(json);
      } catch (err) {
        setError(err.message);
      } finally {
        //setIsLoading(false);
      }
    };

    fetchData();
  }, [apiController]);

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   setController(abortController);

  //   fetch(`${baseUrl}${apiController}`, {
  //     signal: abortController.signal,
  //     credentials: "include",
  //   })
  //     .then((response) => response.json())
  //     .then((json) => setData(json))
  //     .catch((error) => {
  //       if (error.name === "AbortError") {
  //         setError("Cancelled Request");
  //       } else {
  //         setModalAlert({
  //           openModal: true,
  //           message: "Su sesión ha expirado, por favor vuelva a iniciar sesión",
  //           severity: "error",
  //           variant: "filled",
  //         });
  //         window.location.href = "/";
  //       }
  //     })
  //     .finally();

  //   return () => abortController.abort();
  // }, [apiController]);

  // const handleCancelRequest = () => {
  //   if (controller) {
  //     controller.abort();
  //     setError("Cancelled Request");
  //   }
  // };
  return { data, setData, error };
};
