import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

import Collapse from "@mui/material/Collapse";
import { useState } from "react";
import theme from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { set } from "date-fns";

export const ListOptionMenu = ({ showMenu, setShowMenu }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const handleClick = (path) => {
    navigate(path);
  };

  const menuItems = [
    { name: "home", path: "/" },
    { name: "solutions", path: "/service" },
    { name: "features", path: "/subservice" },
    { name: "we", path: "/Nosotros" },
    //{ name: "persons", path: "/Personas" },
    //{ name: "prices", path: "/Calculator" },
  ];

  return (
    <>
      <List>
        {menuItems.map((item) => (
          <ListItem key={item.name}>
            {item.name === "solutions" ? (
              <ListSolutionOptionMenu
                item={item}
                showMenu={showMenu}
                setShowMenu={setShowMenu}
              />
            ) : (
              <ListItemButton onClick={() => handleClick(item.path)}>
                <ListItemText
                  primary={t(`navBar.${item.name}`)}
                  sx={{ fontFamily: theme.Font.primary }}
                />
              </ListItemButton>
            )}
          </ListItem>
        ))}
      </List>
    </>
  );
};

const ListSolutionOptionMenu = ({ item, setShowMenu, showMenu }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleClick = (path) => {
    setOpen(!open);
  };

  const handleNavigate = (path, id) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
    navigate(path);
    setShowMenu(!showMenu);
  };
  return (
    <>
      <ListItemButton onClick={() => handleClick(item.path)}>
        <ListItemText primary={t(`navBar.${item.name}`)} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() =>
              handleNavigate("/service/#link-de-pago", "link-de-pago")
            }
          >
            <ListItemText primary={t("dropDownMenu.title_5")} />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() =>
              handleNavigate(
                "/subservice/#liquida-en-linea",
                "liquida-en-linea"
              )
            }
          >
            <ListItemText primary={t("dropDownMenu.title_2")} />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() =>
              handleNavigate(
                "/subservice/#seguros-estudiantiles",
                "seguros-estudiantiles"
              )
            }
          >
            <ListItemText primary={t("dropDownMenu.title_3")} />
          </ListItemButton>
          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() =>
              handleNavigate(
                "/subservice/#auto-transferencia",
                "auto-transferencia"
              )
            }
          >
            <ListItemText primary={t("dropDownMenu.title_4")} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  );
};
