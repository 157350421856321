import "./App.css";
import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from "react-router-dom";
import {
  Home,
  Nosotros,
  Service,
  SubService,
  Login,
  PreRegistro,
  RegisterAgency,
  AgencyDashboard,
} from "./Components/Home/Pages";
import { Personas } from "./Components/Home/Pages/Personas";
import { CrossModalNotification } from "./Components/CrossComponents/CrossModalNotification";
import Dashboard from "./Components/Home/Pages/Dashboard";
import { Payments, PaymentsList } from "./Components/Payments/Pages";
import { CrossModal, ProtectedRoute } from "./Components/CrossComponents";
import En from "./Components/Home/Data/Lenguajes/en.json";
import Es from "./Components/Home/Data/Lenguajes/es.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { SettlePaymentsList } from "./Components/SettlePayments/Pages/SettlePaymentsList";
import { DetailedPaymentSettle } from "./Components/SettlePayments/Components";
import { CreateUserByAgency } from "./Components/Configurations/Pages/CreateUserByAgency";
import { useLenguageStore, useUserStore } from "./store";
import { PaymentAdmin } from "./Components/Owner/Pages/PaymentAdmin";
import { AgencyAdmin } from "./Components/Owner/Pages/AgencyAdmin";
import { AgencyUserList } from "./Components/Owner/Components/AgencyUserList";
import { Calcualtor } from "./Components/Home/Pages/Calcualtor";
import { ExchangeAdmin } from "./Components/Owner/Pages/ExchangeAdmin";
import { RecoverPassword } from "./Components/Home/Pages/RecoverPassword";
import { ResetPassword } from "./Components/Home/Pages/ResetPassword";

function App() {
  const { lenguage } = useLenguageStore();

  i18n.use(initReactI18next).init({
    resources: {
      en: {
        translation: En,
      },
      es: {
        translation: Es,
      },
    },
    lng: lenguage, // language to use
    interpolation: {
      escapeValue: false,
    },
  });

  return (
    <>
      <RouterProvider router={router} />
      <CrossModalNotification />
      <CrossModal />
    </>
  );
}

const wrapInProtectedRoute = (routes) => {
  return routes.map((route) => {
    return {
      ...route,
      element: <ProtectedRoute>{route.element}</ProtectedRoute>,
    };
  });
};

const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/service",
    element: <Service />,
  },
  {
    path: "/subservice",
    element: <SubService />,
  },
  {
    path: "/Nosotros",
    element: <Nosotros />,
  },
  {
    path: "/Personas",
    element: <Personas />,
  },
  {
    path: "/Login",
    element: <Login />,
  },
  {
    path: "/PreRegistro",
    element: <PreRegistro />,
  },
  {
    path: "/RegisterAgency",
    element: <RegisterAgency />,
  },
  {
    path: "/Calculator",
    element: <Calcualtor />,
  },
  {
    path: "/RecoverPassword",
    element: <RecoverPassword />,
  },
  {
    path: "/ResetPassword",
    element: <ResetPassword />,
  },
  {
    path: "/Dashboard",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: wrapInProtectedRoute([
      { path: "Payments", element: <Payments /> },
      { path: "PaymentsList", element: <PaymentsList /> },
      { path: "SettlePaymentsList", element: <SettlePaymentsList /> },
      { path: "DetailedPaymentSettle", element: <DetailedPaymentSettle /> },
      { path: "CreateUserByAgency", element: <CreateUserByAgency /> },
      {
        path: "PaymentAdmin",
        element: <PaymentAdmin />,
      },
      {
        path: "AgencyAdmin",
        element: <AgencyAdmin />,
      },
      {
        path: "ExchangeAdmin",
        element: <ExchangeAdmin />,
      },
      {
        path: "AgencyUserList",
        element: <AgencyUserList />,
      },
      { path: "Agency", element: <AgencyDashboard /> },
      { path: "*", element: <Dashboard /> },
    ]),
  },
  { path: "*", element: <Home /> },
]);
export default App;
