import { yupResolver } from "@hookform/resolvers/yup";
import { Link, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { PassWordTextField } from "./PassWordTextField";
import { UserTextField } from "./UserTextField";
import { SubmitLoginButton } from "./SubmitLoginButton";
import { useNavigate } from "react-router-dom";
import {
  useStorageModal,
  useStoreModalNotification,
  useUserStore,
} from "../../../store";
import { useTranslation } from "react-i18next";
import { CrossLoader } from "../../CrossComponents";

export const FormLogin = () => {
  const { t, i18n } = useTranslation();
  const { validationshema, initialValues } = useValidateForm(loginForm);
  const { setModalCross } = useStorageModal();
  const { SubmitFormJson } = useFetchPost();
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });
  const { login } = useUserStore();
  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );
  const setNotification = (openModal, message, severity, variant) => {
    setModalAlert({
      openModal: openModal,
      message: message,
      severity: severity,
      variant: variant,
    });
  };
  const setLoader = (openModal) => {
    setModalCross({
      openModal: openModal,
      component: () => <CrossLoader />,
      setValue: () => {},
      formValues: {},
    });
  };

  const onSubmit = (dataLogin) => {
    setLoader(true, setModalCross);
    SubmitFormJson(dataLogin, "Autentication").then((response) => {
      if (!response.ok) {
        setLoader(false);
        setNotification(
          true,
          "Usuario y/o contrasena invalidos",
          "error",
          "filled"
        );
        return;
      }
      response.json().then((data) => {
        login(data);
        setLoader(false);
        switch (data.roles[0]) {
          case "Owner":
            navigate("/Dashboard");
            break;
          case "Administrador":
            navigate("/Dashboard/PaymentsList");
            break;
          case "Asesor":
            navigate("/Dashboard/PaymentsList");
          default:
            break;
        }
      });
    });
  };

  return (
    <>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          justifyContent="center"
          flexDirection="column"
          spacing={2}
          p={5}
        >
          {loginForm.map((cp) => {
            switch (cp.type) {
              case "text":
                return (
                  <UserTextField
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "password":
                return (
                  <PassWordTextField
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "select":
                return (
                  <CrossSelectList
                    key={cp.name}
                    control={control}
                    {...cp}
                    errors={errors}
                  />
                );
              case "submitButton":
                return <SubmitLoginButton key={cp.name} {...cp} />;
            }
          })}
        </Grid>
      </form>

      <Grid container justifyContent="center">
        {/* <Grid item xs={12} sm={6}>
          <Link
            component="button"
            variant="body2"
            onClick={() => navigate("/RegisterAgency")}
          >
            REGISTRATE AQUI
          </Link>
        </Grid> */}
        <Grid item>
          <Link component="button" onClick={() => navigate("/RecoverPassword")}>
            {t("login.link")}
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

const loginForm = [
  {
    type: "text",
    name: "Username",
    label: "Usuario",
    value: "",
    xs: 12,
    iconname: "",
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    type: "password",
    name: "Password",
    label: "Contraseña",
    value: "",
    xs: 12,
    iconname: "",
    validations: [
      {
        type: "required",
      },
    ],
  },
  {
    type: "submitButton",
    name: "Login",
    label: "Login",
    variant: "contained",
    mode: "submit",
    xs: 12,
    validations: [],
  },
];
