import React, { useEffect, useRef, useState } from "react";
import {
  StyledMUIGridContainer,
  Titule,
  Text,
  Styledimg,
} from "../Styled-components";
import { useTranslation } from "react-i18next";
import {
  Grid,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import {
  StyledMUIBoxContainerCalc,
  StyledMUIBoxContainerForm,
  StyledMUISelect,
} from "../../../StyledCrossComponents";
import { CrossInputText, CrossSelectList } from "../../CrossComponents";
import theme from "../../../theme";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchGet } from "../../../Hooks/useFetchGet";
import ImageComponent from "../Styled-components/StyledImageComponent";
import { animationblue2 } from "../../../assets";

export const CalculatorForm = () => {
  const { t, i18n } = useTranslation();
  const elementsForm = GetElementsCalculatorForm();
  const { validationshema, initialValues } = useValidateForm(elementsForm);
  const [calc, setCalc] = useState({
    exchangeRate: 0,
    amount: 0,
    total: 0,
    acronym: "AUD",
    unit: 1,
    invconvertion: 0,
  });

  const userLanguage = navigator.language || navigator.userLanguage;
  const [animate, setAnimate] = useState(false);
  const ref = useRef();
  useEffect(() => {
    setAnimate(true);
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setAnimate(false);
        }
      });
    });

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    reset,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  const formValues = watch();
  useEffect(() => {
    setCalc({
      ...calc,
      //   exchangeRate: calc.exchangeRate,
      amount: parseFloat(formValues.amount.replace(/[^0-9.]/g, "")),
      total: formValues.amount * calc.exchangeRate,
    });
  }, [formValues.amount]);

  const onSubmit = (data) => {
    console.log(data);
  };
  return (
    <>
      <StyledMUIGridContainer container spacing={2} justifyContent={"center"}>
        <Grid item xs={12} md={6}>
          <Titule $textalign={"center"}>{t("calculator.title_1")}</Titule>
          <Text $textalign={"center"}>{t("calculator.description_1")}</Text>
        </Grid>
      </StyledMUIGridContainer>
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <Styledimg
          $top="-80px"
          $left="-350px"
          $width="260px"
          $height="260px"
          $rotate="rotate(770deg)"
          $zindex="1"
          $animate={animate}
        >
          <ImageComponent src={animationblue2} alt="home" />
        </Styledimg>
        <StyledMUIBoxContainerForm width={"70%"}>
          <Grid container alignItems={"center"}>
            <Grid item xs={12}>
              <Titule $textalign={"center"}>{t("calculator.title_2")}</Titule>
            </Grid>
            <Grid item xs={6}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="center" spacing={2} p={5}>
                  {elementsForm.map((item) => {
                    switch (item.componenttype) {
                      case "input":
                        return (
                          <CrossInputText
                            key={item.name}
                            control={control}
                            errors={errors}
                            item={item}
                          />
                        );
                      case "select":
                        return (
                          <CrossSelectList
                            key={item.nameId ? item.nameId : item.name}
                            errors={errors}
                            control={control}
                            item={item}
                            formValues={formValues}
                            setValue={setValue}
                          />
                        );
                      case "SelectDestination":
                        return (
                          <SelectDestination
                            key={item.nameId ? item.nameId : item.name}
                            errors={errors}
                            control={control}
                            item={item}
                            formValues={formValues}
                            setValue={setValue}
                            setCalc={setCalc}
                            calc={calc}
                          />
                        );
                      default:
                        return <></>;
                    }
                  })}
                </Grid>
              </form>
            </Grid>
            <Grid item xs={6}>
              <StyledMUIBoxContainerCalc backgroundColor={theme.Palette.senary}>
                <Text>{`${calc.amount.toLocaleString(userLanguage, {
                  style: "currency",
                  currency: calc.acronym,
                })} = ${calc.total.toLocaleString(userLanguage, {
                  style: "currency",
                  currency: "COP",
                })}`}</Text>
                <hr />
                <Text>{`${calc.unit.toLocaleString(userLanguage, {
                  style: "currency",
                  currency: calc.acronym,
                })} = ${calc.exchangeRate.toLocaleString(userLanguage, {
                  style: "currency",
                  currency: "COP",
                })} = ${calc.invconvertion}`}</Text>
                <Text>{t("calculator.description_2")}</Text>
              </StyledMUIBoxContainerCalc>
            </Grid>
          </Grid>
        </StyledMUIBoxContainerForm>
      </Grid>
    </>
  );
};

const SelectDestination = ({
  item,
  errors,
  control,
  formValues,
  setValue,
  setCalc,
}) => {
  const { data } = useFetchGet(item.apiEndPoint);
  if (data == null) return null;
  const countriesOption = data.filter((i) => i.countryId === 2);

  const handleChange = (e, name) => {
    e.preventDefault();
    const option = data.find((i) => i[item.name] === e.target.value);
    setCalc({
      exchangeRate: option.exchangeRate.exchangeAgency,
      acronym: option.exchangeRate.acronym,
      amount: parseFloat(formValues.amount.replace(/[^0-9.]/g, "")),
      total: formValues.amount * option.exchangeRate.exchangeAgency,
      unit: 1,
      invconvertion: 1 / option.exchangeRate.exchangeAgency,
    });
  };

  return (
    <>
      <Grid item xs={item.xs}>
        <Controller
          name={item.nameId ? item.nameId : item.name}
          control={control}
          defaultValue={item.value}
          sx={item.sx}
          render={({ field }) => (
            <FormControl fullWidth>
              <InputLabel
                id={`select-label-${item.nameId ? item.nameId : item.name}`}
              >
                {item.label}
              </InputLabel>
              <StyledMUISelect
                labelId={`select-label-${
                  item.nameId ? item.nameId : item.name
                }`}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleChange(e, field.name);
                }}
                value={field.value}
                size={item.size}
                disabled={item.disabled}
              >
                <MenuItem value="0">Eliege una opción...</MenuItem>
                {countriesOption?.map((i) => (
                  <MenuItem key={i[item.name]} value={i[item.name]}>
                    {i[item.description]}
                  </MenuItem>
                ))}
              </StyledMUISelect>
              {errors[item.nameId ? item.nameId : item.name] && (
                <FormHelperText id="my-helper-text" error>
                  {errors[item.nameId ? item.nameId : item.name]["message"]}
                </FormHelperText>
              )}
            </FormControl>
          )}
        />
      </Grid>
    </>
  );
};

const GetElementsCalculatorForm = () => {
  const { t, i18n } = useTranslation();
  return [
    {
      componenttype: "select",
      label: t("calculator.select_1"),
      size: "small",
      name: "countryId",
      nameId: "CountryOriginId",
      description: "countryName",
      value: "1",
      margin: "normal",
      xs: 12,
      apiEndPoint: "Country",
      disabled: true,
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      componenttype: "SelectDestination",
      label: t("calculator.select_2"),
      size: "small",
      name: "countryId",
      nameId: "CountryDestinationId",
      description: "countryName",
      value: "",
      margin: "normal",
      xs: 12,
      apiEndPoint: "Country",
      validations: [
        {
          type: "required",
        },
      ],
    },
    {
      componenttype: "input",
      label: t("calculator.input_1"),
      type: "text",
      name: "amount",
      value: "0",
      size: "small",
      margin: "normal",
      xs: 12,
      validations: [
        {
          type: "required",
        },
      ],
    },
  ];
};
