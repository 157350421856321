import React, { useEffect } from "react";
import {
  StyledMUIBoxContainerForm,
  StyledMUIButton,
} from "../../../StyledCrossComponents";
import { useValidateForm } from "../../../Hooks/useValidateForm";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CrossCheck,
  CrossInputText,
  CrossSelectList,
} from "../../CrossComponents";
import { Grid } from "@mui/material";
import theme from "../../../theme";
import { useFetchPost } from "../../../Hooks/useFetchPost";
import { CrossModalNotification } from "../../CrossComponents/CrossModalNotification";
import { useForm } from "react-hook-form";

import { SelectTypeIdeAgency, SelectFile } from "./index";
import { useStoreModalNotification } from "../../../store";
import { useNavigate } from "react-router-dom";

export const StepForm = ({
  handleNext,
  handleBack,
  activeStep,
  dataSteps,
  dataStepActive,
}) => {
  const { validationshema, initialValues } = useValidateForm(
    dataStepActive.form
  );

  const setModalAlert = useStoreModalNotification(
    (state) => state.setModalAlert
  );

  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
    register,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationshema),
  });

  let formValues = watch();

  const { SubmitFormMedia } = useFetchPost();
  const onSubmit = (data) => {
    handleNext();
    const formData = new FormData();
    if (activeStep === dataSteps.length - 1) {
      for (let key in formValues) {
        if (key === "myFile") {
          formData.append("myFile", formValues.myFile[0]);
        }
        if (key === "documentFile") {
          formData.append("myFile", formValues.documentFile[0]);
        }
        if (key === "agencyId") {
          formData.append(key, 0);
        }
        formData.append(key, formValues[key]);
      }
      SubmitFormMedia(formData, "Agency/AddAgencyUser").then((response) => {
        if (!response.ok) {
          setModalAlert({
            openModal: true,
            message: "Error en al crear la agencia",
            severity: "error",
            variant: "filled",
          });
          return;
        }
        setModalAlert({
          openModal: true,
          message:
            "Agencia creada correctamente, se ha enviado un correo para su confirmacion.",
          severity: "success",
          variant: "filled",
        });
      });
    }
  };

  return (
    <>
      <StyledMUIBoxContainerForm>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Grid container justifyContent="center" spacing={2} p={5}>
            {dataStepActive.form.map((item) => {
              switch (item.componenttype) {
                case "input":
                  return (
                    <CrossInputText
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                    />
                  );
                case "selecttypeidentification":
                  return (
                    <SelectTypeIdeAgency
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "select":
                  return (
                    <CrossSelectList
                      key={item.nameId ? item.nameId : item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                case "check":
                  return (
                    <CrossCheck
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      register={register}
                    />
                  );
                case "inputfile":
                  return (
                    <SelectFile
                      key={item.name}
                      errors={errors}
                      control={control}
                      item={item}
                      formValues={formValues}
                      setValue={setValue}
                    />
                  );
                default:
                  return null;
              }
            })}
            <Grid container justifyContent={"space-between"} mt={2}>
              <Grid item>
                <StyledMUIButton
                  backgroundcolor={theme.Palette.tertiary}
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Regresar
                </StyledMUIButton>
              </Grid>
              <Grid item>
                <StyledMUIButton
                  type="submit"
                  backgroundcolor={theme.Palette.tertiary}
                >
                  {activeStep === dataSteps.length - 1
                    ? "Terminar"
                    : "Siguiente"}
                </StyledMUIButton>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </StyledMUIBoxContainerForm>
      <CrossModalNotification />
    </>
  );
};
