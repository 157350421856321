import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";
import { StyledMUISelect } from "../../StyledCrossComponents";
import { useStorePaymentStep } from "../../store";
import { useFetchGet } from "../../Hooks/useFetchGet";

export const CrossSelectList = ({
  item,
  errors,
  control,
  formValues,
  setValue,
}) => {
  const setPaymentStep = useStorePaymentStep((state) => state.setPaymentStep);
  const { data } = useFetchGet(
    !item.anidado
      ? item.apiEndPoint
      : item.apiEndPoint.replace(
          "*",
          formValues[item.param.name] ? formValues[item.param.name] : item.value
        )
  );

  if (data == null) return null;

  const options = item.filter
    ? data.filter((i) => item.filter.includes(i.id))
    : data;

  const handleChange = (e, name) => {
    e.preventDefault();
    if (name === "paymentTypeId") {
      setPaymentStep({
        paymentTypeDescription: data.find(
          (i) => i[item.name] === e.target.value
        ).type,
      });
    }
    if (name === "StudentCountryId") {
      setValue("StudentCityId", "0");
    }
    // if (name === "PaymentCountryOrigenId") {
    //   setValue(
    //     "currency",
    //     data.find((i) => i[item.name] === e.target.value).currency
    //   );
    // }
  };

  return (
    <Grid item xs={item.xs}>
      <Controller
        name={item.nameId ? item.nameId : item.name}
        control={control}
        defaultValue={item.value}
        sx={item.sx}
        render={({ field }) => (
          <FormControl fullWidth>
            <InputLabel
              id={`select-label-${item.nameId ? item.nameId : item.name}`}
            >
              {item.label}
            </InputLabel>
            <StyledMUISelect
              labelId={`select-label-${item.nameId ? item.nameId : item.name}`}
              onChange={(e) => {
                field.onChange(e.target.value);
                handleChange(e, field.name);
              }}
              value={field.value}
              size={item.size}
              disabled={item.disabled}
            >
              <MenuItem value="0">Eliege una opción...</MenuItem>
              {options?.map((i) => (
                <MenuItem key={i[item.name]} value={i[item.name]}>
                  {i[item.description]}
                </MenuItem>
              ))}
            </StyledMUISelect>
            {errors[item.nameId ? item.nameId : item.name] && (
              <FormHelperText id="my-helper-text" error>
                {errors[item.nameId ? item.nameId : item.name]["message"]}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};
