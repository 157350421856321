import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
import theme from "../../../theme";

export const EstimatePayment = ({ formValues }) => {
  return (
    <Grid item xs={6}>
      <Typography variant="h6" gutterBottom>
        Usted paga en COP:
      </Typography>
      <StyledMUIBoxEstimatePayment>
        {formValues.estimatePayment.toLocaleString("en-CO", {
          style: "currency",
          currency: "COP",
        })}
      </StyledMUIBoxEstimatePayment>
    </Grid>
  );
};

const StyledMUIBoxEstimatePayment = styled(Box)`
  && {
    text-align: center;
    color: black;
    border-radius: 10px;
    padding: 10px;
    background-color: ${theme.Palette.senary};
  }
`;
